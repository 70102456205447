import React, { useState } from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import {
  RiMoneyDollarCircleLine,
  RiLineChartLine,
  RiMedalLine,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
// import socket from '~/services/socket';
import Inicio from '~/subpages/Inicio';

// import { OverlayTrigger } from 'react-bootstrap';
import Modal from '~/components/Modal';

import { useProfile } from '~/hooks/profile';
import Loading from '~/components/Loading';
import * as S from './styles';
// import { useAuth } from '~/hooks/auth';

// import Tooltip from '~/components/Tooltip';

export default function Resume() {
  const {
    dataProfile,
    // setDataProfile,
    dataRanking,
    // setDataRanking,
  } = useProfile();
  // const { user } = useAuth();
  const [modalShow, setModalShow] = useState('');

  // useEffect(() => {
  //   socket.connect();
  //   const ws = socket.subscribe(`notificationsClient:${user.key}`);
  //   ws.on('clientAcquire', data => {
  //     if (data) {
  //       setDataRanking(data.ranking);
  //       dataProfile.wallet = data.wallet;
  //       setDataProfile(dataProfile.wallet);
  //     }
  //   });
  // }, [dataProfile, setDataProfile, setDataRanking, user]);

  // console.log(dataProfile);

  return (
    <Inicio>
      {dataProfile.key ? (
        <S.BackgroundSection>
          <S.BackgroundDiv>
            <ul>
              <li>
                <span className="clasName">Créditos</span>
              </li>
              <li>
                <span className="clasNumberCred">
                  {dataProfile.wallet.creditUsed <= 9
                    ? `0${dataProfile.wallet.creditUsed}`
                    : dataProfile.wallet.creditUsed}
                </span>
              </li>
              <li>
                <Link
                  to="/dashboard/adicionar-creditos/planos"
                  className="directionTab"
                >
                  Adicionar créditos
                </Link>
              </li>
              <li>
                <button
                  className="clasLink"
                  type="button"
                  onClick={() => setModalShow('credits')}
                >
                  <span className="clasLink" variant="secondary">
                    <BsQuestionCircle />
                    Como adicionar créditos
                  </span>
                </button>
              </li>
            </ul>
          </S.BackgroundDiv>
          <S.BackgroundDiv>
            <ul>
              <li>
                <span className="clasName">Pontos</span>
              </li>
              <li>
                <span className="clasNumber">
                  {dataProfile.wallet.scoreUsed <= 9
                    ? `0${dataProfile.wallet.scoreUsed}`
                    : dataProfile.wallet.scoreUsed}
                </span>
              </li>
              <li>
                <Link to="/dashboard/adicionar-pontos" className="directionTab">
                  Adicionar pontos
                </Link>
              </li>
              <li>
                <button
                  className="clasLink"
                  type="button"
                  onClick={() => setModalShow('scores')}
                >
                  <span className="clasLink">
                    <BsQuestionCircle />
                    Como ganhar pontos
                  </span>
                </button>
              </li>
            </ul>
          </S.BackgroundDiv>
          <S.BackgroundDiv>
            <ul>
              <li>
                <span className="clasName">Ranking</span>
              </li>
              <li>
                <span className="clasNumber">{dataRanking ? dataRanking.position : 0 }º</span>
                {/* <aside>
                  <font id="fontName">
                    Na última semana <br /> {dataRanking.pastPosition} posições
                  </font>

                  <BsChevronUp size={40} />
                </aside> */}
              </li>
              <li>
                {/* <span className="clasLink top">Ranking geral</span> */}
                <Link to="/dashboard/ranking" className="directionTab">
                  Ranking geral
                </Link>
              </li>
              <li>
                <button
                  className="clasLink"
                  type="button"
                  onClick={() => setModalShow('ranking')}
                >
                  <span className="clasLink">
                    <BsQuestionCircle />
                    Como subir no ranking
                  </span>
                </button>
              </li>
            </ul>
          </S.BackgroundDiv>
        </S.BackgroundSection>
      ) :
        <Loading />
      }

      {/* Modais */}

      {modalShow === 'credits' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <h1 className="scores">
            Como Adicionar Créditos <RiMoneyDollarCircleLine />
          </h1>
          <p className="scores">
            O que são Créditos: Crédito é a moeda utilizada na AcordeOn®. Cada
            Crédito equivale a 01 (UM) registro de música, que pode ser
            utilizado a qualquer momento pois eles NÃO têm validade.
          </p>
          <p className="scores">
            Como adicionar Créditos: Adicione créditos clicando em “Adicionar
            Créditos”, em seguida escolha a sua melhor opção, que pode ser
            crédito individual ou selecione a quantia que desejar, e depois efetue o pagamento, que pode
            ser feito através de cartão de crédito ou boleto bancário. E no
            cartão pode ser parcelado, ein?!
          </p>

          <Link
            to="/dashboard/adicionar-creditos/planos"
            className="modalCredits"
          >
            Adicionar Créditos
          </Link>
        </Modal>
      )}

      {modalShow === 'scores' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <h1 className="scores">
            Como Ganhar Pontos <RiMedalLine />
          </h1>
          <p className="scores">
          Para ganhar pontos basta comprar créditos e registrar sua música. Ao comprar 1 crédito você ganha 1 ponto e ao registrar 1 música você ganha mais 1 ponto.
          </p>

          {/* <Link to="/dashboard/adicionar-pontos" className="scores">
            Seguir
          </Link> */}
        </Modal>
      )}

      {modalShow === 'ranking' && (
        <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
          <h1 className="scores">
            Como Subir no Ranking <RiLineChartLine />
          </h1>
          <p className="scores">
            A posição do ranking é baseada no número total de pontos que o compositor ganhou desde o seu cadastro até o momento atual. Sendo assim, o compositor com maior número de pontos fica na primeira colocação e o com menor número de pontos na última colocação. Dessa forma, para subir no ranking você deve pontuar o máximo possível.
          </p>
        </Modal>
      )}
    </Inicio>
  );
}
