import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BsChevronDown, BsSearch, BsDownload } from 'react-icons/bs';
import { BiShareAlt } from 'react-icons/bi';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Moment from 'moment';

import Index from '~/pages/Index';
import Modal from '~/components/Modal';
import { lower } from '~/utils/lower';
import Posts from '~/components/Posts';
import Highlight from '~/components/Highlight';
import { ReactComponent as MusicIcon } from '~/assets/img/musicIcon.svg';
import { ReactComponent as UndrawNoData } from '~/assets/img/undraw_no_data.svg';
// import { ReactComponent as Share } from '~/assets/img/share1.svg';

import api from '~/services/api';
import { useProfile } from '~/hooks/profile';
// import { useAuth } from '~/hooks/auth';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import * as S from './styles';

const MinhasMusicas = () => {
  const { dataProfile, dataPerson } = useProfile();
  const history = useHistory();
  const [word, setWord] = useState('');
  const [modalShow, setModalShow] = useState('');
  // const [redes, setRedes] = useState(false);
  const [musics, setMusics] = useState(null);
  // const [download, setDownload] = useState('');
  // const [inteiro, setInteiro] = useState();
  const [downloadMP3, setDownloadMP3] = useState();
  const [musicRegister, setMusicRegister] = useState();
  const [MP3, setMP3] = useState();
  const [extension, setExtension] = useState();

  const [inteiro, setInteiro] = useState();
  const [currentPages, setCurrentPages] = useState(1);
  const limit = 10;


  useEffect(() => {
    api.get(`/music?page=${currentPages}&limit=${limit}`).then(response => {
      const { data } = response.data;
      const { total } = response.data;

      setMusics(data);
      setInteiro(total);
    });
  }, [currentPages]);

  // console.log('musics', musics);

  const handleChange = useCallback(e => {
    setWord(e.target.value);
  }, []);

  const handleClickModalMusic = async music => {
    setModalShow(`${music.key}audio`);

    const type = music.file.name.split('.').pop();

    setExtension(type);

      if (music.linkStream) {
        const urlStream = await `${process.env.REACT_APP_URL_API}/api/v1/guest/music/stream/${music.key}`;
        const urlDownloadMP3 = await `${process.env.REACT_APP_URL_API}/api/v1/guest/music/stream/${music.key}`;
        const urlMusicRegister = await `${process.env.REACT_APP_URL}/musica-registrada/${music.key}`;

        setMP3(urlStream);
        setDownloadMP3(urlDownloadMP3);
        setMusicRegister(urlMusicRegister);
      }

  }

  //   const tokenT = localStorage.getItem('@AcordeOn:token');

  //   const config = {
  //     headers: { Authorization: `bearer ${tokenT}` }
  //   };

  //   const bodyParameters = {
  //     key: "value"
  //  };


  //   api
  //     .get(`/music/stream/${music.key}`,
  //     bodyParameters,
  //     config
  //     )
  //     .then(response => {
  //       console.log('posts', response);
  //       setExtension(response.config.baseURL + response.config.url)
  //     })
  //     .catch(err => console.log('err', err));
  // };

  const handleRegisterMusic = () => {
    if (dataProfile.wallet.creditUsed > 0 && dataPerson.fullname !== null) {
      history.push('/dashboard/registrar-musica');
    } else if (dataPerson.fullname === null) {
      return setModalShow('showNotProfile');
    } else if (dataProfile.wallet.creditUsed <= 0) {
      return setModalShow('showNotCredit');
    }
  };

  const downloadMusic = () => {
    window.open(downloadMP3, '_blank');
  };

  const sharedMusic = () => {
    window.open(`http://api.whatsapp.com/send?text=${musicRegister}`, '_blank');
  };

  // const shareMusic = () => {
  //   setRedes(true)
  // };

  // const test = new Audio(stream);
  // console.log('test', test);
  // console.log('musics', musics);

  // const allLetter = musics?.map(music => music.letter);

  // let descr = '';
  // for (let i = 0; i < allLetter?.length; i++) {
  //   descr += `\n ${allLetter[i]}`;
  // }
  // descr = descr.replace(/\s/g, '\n');
  // console.log('descr', <div>descr</div>);

  // const useAudio = url => {
  //   const [audio] = useState(new Audio(url));
  //   const [playing, setPlaying] = useState(true); // nothing is playing on default

  //   const toggle = () => {
  //     setPlaying(!playing);
  //     playing ? audio.play() : audio.pause();
  //     console.log(`audio is playing${toggle}`);
  //   };

  //   return [playing, toggle];
  // };

  // const [toggle] = useAudio(extension);

  // console.log('ss', toggle);

  return (
    <Index>
      <Posts />
      {/* <Suspense fallback={<Loading />}> */}
      {musics !== null ? (
        <S.BackgroundSection>
          {musics.length === 0 ? (
            <div className="notMusic">
              <UndrawNoData />
              <span>Você não possui músicas registradas</span>
              <button type="button" onClick={() => handleRegisterMusic()}>
                Registrar música agora
              </button>
            </div>
          ) : (
            <>
              <div>
                <h1>Minhas Músicas</h1>
                <div className="inputIcon">
                  <input
                    type="text"
                    value={word}
                    placeholder="Pesquisar"
                    onChange={handleChange}
                  />
                  <BsSearch size={13} />
                </div>
              </div>
              <div className="scroll">
                <table>
                  <thead>
                    <tr>
                      <th className="date">
                        <BsChevronDown />
                        DATA DE REGISTRO
                      </th>
                      <th>NOME</th>
                      <th>CO-AUTOR</th>
                      <th>COMPOSIÇÃO</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {musics
                      .filter(music => lower(music.title).includes(lower(word)))
                      .map(music => (
                        <tr key={music.id}>
                          <td>
                            {Moment(music.created_at).format('DD/MM/YYYY')}
                          </td>
                          <td>
                            <Highlight toHighlight={word}>
                              {music.title}
                            </Highlight>
                          </td>
                          <td>
                            {music.co_author[0] ? music.co_author[0].name : '-'}
                          </td>
                          <td className="icon">
                            <button
                              type="button"
                              onClick={() => handleClickModalMusic(music)}
                            >
                              <MusicIcon />
                            </button>
                            {/* <button type="button" onClick={downloadMusic}>
                              <Share />
                            </button> */}
                            {modalShow === `${music.key}audio` && (
                              <Modal
                                isOpen={modalShow}
                                onClickClose={() => setModalShow('')}
                              >
                                <div className="myMusics">
                                  <ul>
                                    <li className="leftItens">
                                      <span className="nameMusic">
                                        {music.title}
                                      </span>
                                      <span className="nameComposer">
                                        {dataProfile.nickname} (Autor)
                                      </span>
                                      {/* {music.co_author[0] && (
                                        <span className="nameComposer">
                                          {music.co_author[0].name}
                                        </span>
                                      )} */}
                                      {music?.co_author.map(co => (
                                        <span
                                          key={co.name}
                                          className="nameComposer"
                                        >
                                          {co.name} (Co-autor)
                                        </span>
                                      ))}
                                      {music?.interpreter.map(co => (
                                        <span
                                          key={co.name}
                                          className="nameComposer"
                                        >
                                          {co.name} (Intérprete)
                                        </span>
                                      ))}
                                    </li>
                                    <li className="audioPlay">
                                      {extension === 'mp3' ? (
                                        <AudioPlayer
                                          layout="horizontal"
                                          customAdditionalControls={[]}
                                          showJumpControls={false}
                                          customVolumeControls={[]}
                                          src={MP3}
                                        />
                                      ) : (
                                        <span>Composição sem arquivo MP3</span>
                                      )}
                                    </li>
                                    <li className="iconDown">
                                      <BsDownload onClick={downloadMusic} />
                                      <BiShareAlt onClick={sharedMusic} />
                                    </li>
                                  </ul>
                                  <ul>
                                    <li>
                                      {(music.letter.length <= 1 && music.letter[0] === "") ? (
                                        <p>Composição sem letra</p>
                                      ) : (
                                        music?.letter.map(letra => (
                                          <p key={letra}>
                                            {letra !== '' ? letra : <br />}
                                          </p>
                                        ))
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </Modal>
                            )}
                            {/* <PdfIcon onClick={() => setModalShow('pdfModal')} />
                            {modalShow === 'pdfModal' && (
                              <Modal
                                isOpen={modalShow}
                                onClickClose={() => setModalShow('')}
                              >
                                <p>PDF</p>
                              </Modal>
                            )} */}
                          </td>
                          <td
                            id={
                             (( music.status === 'baixar certificado')||
                              ( music.status === 'concluído')) ? 'green' : 'orange'
                            }
                          >

                            {
                              (( music.status === 'baixar certificado')||
                              ( music.status === 'concluído')) ?
                              (
                                <button type="button" onClick={()=>{window.open(music.linkCertificate, '_blank')}}>
                                  <span>Baixar certificado</span>
                                </button>
                                // <a className='link02' onClick={openWindowsPDF(music)} >{music.status}</a>
                              ):
                              (
                                <span >{music.status}</span>
                              )
                            }

                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {inteiro && (
            <Pagination
              limit={limit}
              total={inteiro}
              setCurrentPages={setCurrentPages}
            />
          )}

          {/* Modais */}

          {modalShow === 'showNotProfile' && (
            <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
              <h1 className="h1ButtonRegisterMusic">
                Falta pouco para você se tornar um grande compositor de sucesso
              </h1>
              <p className="pButtonRegisterMusic">
                Complete seu cadastro para adicionar créditos
              </p>

              <Link className="buttonModal" to="/dashboard/perfil">
                Completar cadastro
              </Link>
            </Modal>
          )}

          {modalShow === 'showNotCredit' && (
            <Modal isOpen={modalShow} onClickClose={() => setModalShow('')}>
              <h1 className="h1ButtonRegisterMusic">
                Falta pouco para você se tornar um grande compositor de sucesso
              </h1>
              <p className="pButtonRegisterMusic">
                Garanta a segurança de sua obra adicionando crédito para
                registrar sua música
              </p>

              <Link
                className="buttonModal"
                to="/dashboard/adicionar-creditos/planos"
              >
                Adicionar crédito
              </Link>
            </Modal>
          )}
        </S.BackgroundSection>
      ) : <Loading />}
      {/* </Suspense> */}
    </Index>
  );
};

export default MinhasMusicas;
