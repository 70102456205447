import styled from 'styled-components';

export const DivPosts = styled.div`
  width: 100%;
  height: 80px;
  background: linear-gradient(
    263.7deg,
    rgba(119, 55, 198, 0.8) 1.26%,
    rgba(188, 14, 165, 0.8) 96.99%
  );

  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    font-size: 30px;
    color: #FFFFFF !important;
  }

  &::before {
    z-index: -1;

    position: absolute;
    content: '';
    width: 75%;
    height: auto;
    background: linear-gradient(
      263.7deg,
      rgba(119, 55, 198, 0.8) 1.26%,
      rgba(188, 14, 165, 0.8) 96.99%
    );
  }

  h1 {
    font-family: 'UniviaRegular';
    color: #ffffff;
    font-size: 23px;
    margin-bottom: 0;

    @media (max-width: 650px) {
      font-size: 18px;
    }
    @media (max-width: 550px) {
      font-size: 15px;
    }
    @media (max-width: 450px) {
      margin-left: 15px;
    }
    @media (max-width: 350px) {
      display: none;
    }

    span {
      font-size: 30px;
      color: #FFFFFF !important;
    }
  }

  a {
    font-family: 'Roboto', sans-serif;
    color: #fafafa;
    font-size: 16px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 15px;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    line-height: 15px;

    &:hover {
      box-shadow: 0 0 2px 2px #f34d21;
    }
    @media (max-width: 815px) {
      margin-left: 10px;
    }
    @media (max-width: 550px) {
      font-size: 15px;
    }
    @media (max-width: 350px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 1000px) {
    padding-left: 10px;
  }
  @media (max-width: 850px) {
    padding-left: 30px;
  }

  div.center {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
