import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import Index from '~/pages/Index';
import Posts from '~/components/Posts';
import Check from '~/assets/img/check.svg';
import { useToast } from '~/hooks/toast';

import * as S from './styles';

export default function Registered() {
  const { addToast } = useToast();

    useEffect(() => {
      addToast({
        type: 'success',
        title: 'Registro',
        description: 'Pedido de registro solicitado com sucesso!',
      });
    }, [addToast]);

  return (
    <Index>
      <Posts />
      <S.SectionNotMusic>
        <div>
          <img src={Check} alt="Música enviada para registro com sucesso" />

          <span className="sucess">
            Solicitação de registro de música realizada com sucesso!
          </span>
          <span className="validation">
            Sua música levará até 48 horas úteis para ser registrada. <br /> Em breve
            você receberá um e-mail com o certificado.
          </span>
          <Link to="/dashboard/inicio/resumo">Voltar para o início</Link>
        </div>
      </S.SectionNotMusic>
    </Index>
  );
}
