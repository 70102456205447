import styled from 'styled-components';
import { shade } from 'polished';

export const BackgroundSection = styled.section`
  font-family: 'Roboto', sans-serif;
  margin: 50px 150px;
  color: #ffffff;

  div.data {
    width: 100%;
    border-radius: 5px;
    padding: 32px;
    background-color: #222222;
    margin: 20px 0;

    label {
      margin: 0;
    }
  }

  h1 {
    font-family: 'UniviaRegular';
    font-size: 28px;
    margin-bottom: 20px;
  }

  @media (max-width: 1000px) {
    margin: 50px 100px;
  }

  @media (max-width: 700px) {
    margin: 15px;
  }
`;

export const DivAvatar = styled.div`
  /* margin: 40px 0; */
  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    li {
      cursor: pointer;
      margin: 5px;

      button.buttonAvatar {
        border: 0;
      }

      img {
        width: 3.8rem;
      }
    }
  }

  & label {
    font-family: 'Roboto', sans-serif;
    color: #fafafa;
    font-size: 18px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 20px;
    outline: none;
    border: 0;
    padding: 0.5rem 2rem;
    cursor: pointer;

    input {
      display: none;
    }

    &:hover {
      box-shadow: 0 0 2px 2px #f34d21;
    }
  }
  & label.active {
    display: none;
  }
`;

export const DivPersonal = styled.div`
  div.buttonsInserts {
    button {
      margin: 15px 0;
      background: none;
      border: none;

      font-size: 18px;
      color: #fff;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }

    button.select {
      padding: 10px;
      margin: 0;
      color: #f9f9f9;
      font-size: 16px;

      &:hover {
        color: ${shade(0.2, '#F9F9F9')};
        background: #222222;
        width: 100%;
        text-align: left;
      }
    }

    div.selectContact {
      display: none;
    }

    div.active {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0 0 10px 10px;
      background: #2e2e2e;
    }
  }

  /* div.inputsRowContact {
    display: flex;

    div.divColumn45Right {
      width: 45%;
      margin-right: 1.6rem;
    }
  } */

  div.inputsRow {
    display: flex;
    justify-content: space-between;

    button {
      height: 0;
      border: 0;
      margin-top: 35px;
      color: #87868b;

      &:hover {
        cursor: pointer;
        color: ${shade(0.2, '#f5134b')};
      }
    }
  }

  div.divColumn100,
  div.divColumn48,
  div.divColumn45,
  div.divColumn45Right,
  div.divColumn30,
  div.divColumn20,
  div.divColumn67 {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    //div Input
    div {
      background: #272727;
    }
    input:-webkit-autofill {
        -webkit-text-fill-color: #ffffff !important;
        -webkit-box-shadow: 0 0 0px 1000px #272727 inset;
    }
  }

  div.divColumn100 {
    width: 100%;
  }

  div.headerProfileName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span.know {
      /* width: 90px;
      position: relative;
      top: 3px;
      left: 225px; */
      font-size: 14px;
      color: #f34d21;
      margin-bottom: 20px;

      svg {
        margin: 0 5px 2px 0;
      }

      &:hover {
        cursor: help;
        color: ${shade(0.2, '#f34d21')};
      }

      @media (max-width: 900px) {
        font-size: 14px;
      }
    }
  }

  div.divColumn48 {
    width: 48%;

    .cpf {
      cursor: no-drop;
    }

    div#genderNone {
      display: none;
    }
    //Select

    select:focus {
      box-shadow: 0 0 0 2px #fe5812;
    }
    div.select {
      border-radius: 5px;
      width: 100%;
      height: 3rem;
      color: #fff;
      border: 2px solid #363636;

      display: flex;
      align-items: center;

      option {
        border: 2px solid #363636;
        cursor: pointer;
      }

      select {
        background: #272727;
        padding: 0 12px;
        flex: 1;
        border-radius: 5px;
        border: 0;
        width: 100%;
        height: 44px;
        color: #fff;
        border-right: 12px solid #272727;
        cursor: pointer;

        /* -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; */

        @media (max-width: 780px) {
          height: 30px;
        }
      }
    }
  }

  div.divColumn45 {
    width: 45%;
  }

  div.divColumn30 {
    width: 30%;
  }

  div.divColumn67 {
    width: 67%;
  }

  div.divColumn20 {
    width: 20%;

    div#stateUFNone {
      display: none;
    }
    //Select

    select:focus {
      box-shadow: 0 0 0 2px #fe5812;
    }
    div.select {
      border-radius: 5px;
      width: 100%;
      height: 3rem;
      color: #fff;
      border: 2px solid #363636;

      display: flex;
      align-items: center;

      option {
        border: 2px solid #363636;
        cursor: pointer;
      }

      select {
        background: #272727;
        padding: 0 12px;
        flex: 1;
        border-radius: 5px;
        border: 0;
        width: 100%;
        height: 44px;
        color: #fff;
        border-right: 12px solid #272727;
        cursor: pointer;

        /* -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; */

        @media (max-width: 780px) {
          height: 30px;
        }
      }
    }
  }

  div.divPassword {
    margin: 40px 0;

    div.hr:after,
    div.hr:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #363636;
      display: block;
      margin: 15px 0;
    }

    div.line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.passColumn {
        display: flex;
        flex-direction: column;

        span {
          color: #7c7c7d;
          font-size: 16px;
        }

        span.pass {
          color: #ffffff;
        }
      }

      a.clasLink,
      li.clasLink {
        color: #fe5812;
        font-size: 16px;
        margin-top: 11px;
        list-style: none;

        &:hover {
          cursor: pointer;
          color: ${shade(0.2, '#f25812')};
        }
      }
    }
  }

  button.saveData {
    margin: 0 auto;
    display: flex;
    font-family: 'Roboto', sans-serif;
    color: #fafafa;
    font-size: 18px;
    background: linear-gradient(251.45deg, #f5134b 1.26%, #f34d21 96.99%);
    border-radius: 20px;
    outline: none;
    border: 0;
    padding: 0.5rem 5rem;

    &:hover {
      box-shadow: 0 0 2px 2px #f34d21;
    }
  }

  button.active {
    display: none;
  }

  img {
    display: block;
    margin: 0 auto;
  }
`;
